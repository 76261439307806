import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { MarketIndicatorTemplateType } from '../../../../../Generated/Raven-Demeter';
import addNewDataSource from '../../../../Assets/Icons/expand.svg';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { UpsertMarketIndicatorFactorRequest } from '../MarketIndicatorsManagementDefinitions';
import MarketIndicatorFactorDataSource from './MarketIndicatorFactorDataSource';
import styles from './MarketIndicatorFactorPage.module.scss';
import MarketFactorMathOperationDropdown from './MarketIndicatorsFactorFields/Common/MarketFactorMathOperationDropdown';

interface IMarketIndicatorFactorDataSourceAreaProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
    numberOfDataSourceSections: number;
    setNumberOfDataSourceSections: (numberOfDataSourceSections: number) => void;
    useSecondary?: boolean;
}

const MarketIndicatorFactorDataSourceArea: React.FC<IMarketIndicatorFactorDataSourceAreaProps> = (props: IMarketIndicatorFactorDataSourceAreaProps) => {
    const [translations] = useLanguage();

    const market = props.useSecondary
        ? props.upsertMarketIndicatorFactorRequest.secondaryMarket! ?? props.upsertMarketIndicatorFactorRequest.market
        : props.upsertMarketIndicatorFactorRequest.market;
    const leadingIndicatorType = props.useSecondary
        ? props.upsertMarketIndicatorFactorRequest.secondaryLeadingIndicatorType ?? props.upsertMarketIndicatorFactorRequest.leadingIndicatorType
        : props.upsertMarketIndicatorFactorRequest.leadingIndicatorType;
    const region = props.useSecondary
        ? props.upsertMarketIndicatorFactorRequest.secondaryRegion ?? props.upsertMarketIndicatorFactorRequest.region
        : props.upsertMarketIndicatorFactorRequest.region;
    const subRegion = props.useSecondary
        ? props.upsertMarketIndicatorFactorRequest.secondarySubRegion ?? props.upsertMarketIndicatorFactorRequest.subRegion
        : props.upsertMarketIndicatorFactorRequest.subRegion;
    const commodity = props.useSecondary
        ? props.upsertMarketIndicatorFactorRequest.secondaryCommodity ?? props.upsertMarketIndicatorFactorRequest.commodity
        : props.upsertMarketIndicatorFactorRequest.commodity;
    const extraParameters = props.useSecondary
        ? props.upsertMarketIndicatorFactorRequest.secondaryExtraParameters ?? props.upsertMarketIndicatorFactorRequest.extraParameters
        : props.upsertMarketIndicatorFactorRequest.extraParameters;
    const dataFrequency = props.useSecondary
        ? props.upsertMarketIndicatorFactorRequest.secondaryDataFrequency ?? props.upsertMarketIndicatorFactorRequest.dataFrequency
        : props.upsertMarketIndicatorFactorRequest.dataFrequency;

    useEffect(() => {
        const templateTypeIsAllowed =
            props.upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.ForwardCurve &&
            props.upsertMarketIndicatorFactorRequest?.templateType !== MarketIndicatorTemplateType.Speculative;

        if (!templateTypeIsAllowed) {
            props.setNumberOfDataSourceSections(1);
        } else if (props.upsertMarketIndicatorFactorRequest.secondaryMarket) {
            props.setNumberOfDataSourceSections(2);
        }
    }, [props.upsertMarketIndicatorFactorRequest?.templateType]);

    return (
        <>
            <MarketIndicatorFactorDataSource
                title={
                    props.useSecondary
                        ? `${translations.marketIndicatorsManagement.headers.dataSource} 2`
                        : translations.marketIndicatorsManagement.headers.dataSource
                }
                market={market}
                region={region}
                subRegion={subRegion}
                commodity={commodity}
                extraParameters={extraParameters}
                leadingIndicatorType={leadingIndicatorType}
                templateType={props.upsertMarketIndicatorFactorRequest.templateType}
                dataFrequency={dataFrequency}
                handleChange={(
                    updatedMarket,
                    updatedFundamentalCategory,
                    updatedLeadingIndicatorType,
                    updatedRegion,
                    updatedSubRegion,
                    updatedCommodity,
                    updatedExtraParameters,
                    updatedDataFrequency,
                ) => {
                    if (props.useSecondary) {
                        props.setUpsertMarketIndicatorFactorRequest({
                            ...props.upsertMarketIndicatorFactorRequest,
                            secondaryMarket: updatedMarket,
                            secondaryFundamentalCategory: updatedFundamentalCategory,
                            secondaryLeadingIndicatorType: updatedLeadingIndicatorType,
                            secondaryRegion: updatedRegion!,
                            secondarySubRegion: updatedSubRegion,
                            secondaryCommodity: updatedCommodity,
                            secondaryExtraParameters: updatedExtraParameters,
                            secondaryDataFrequency: updatedDataFrequency!,
                        });
                    } else {
                        props.setUpsertMarketIndicatorFactorRequest({
                            ...props.upsertMarketIndicatorFactorRequest,
                            market: updatedMarket,
                            fundamentalCategory: updatedFundamentalCategory,
                            leadingIndicatorType: updatedLeadingIndicatorType,
                            region: updatedRegion!,
                            subRegion: updatedSubRegion,
                            commodity: updatedCommodity,
                            extraParameters: updatedExtraParameters,
                            dataFrequency: updatedDataFrequency!,
                        });
                    }
                }}
            />
            {!props.useSecondary && props.numberOfDataSourceSections === 1 && (
                <button type="button" onClick={() => props.setNumberOfDataSourceSections(2)} className={styles.indicator_add_and_edit_add_data_source}>
                    <img src={addNewDataSource} alt="#" /> {translations.marketIndicatorsManagement.text.addDataSourceSection}
                </button>
            )}
            {!props.useSecondary && props.numberOfDataSourceSections === 2 && (
                <>
                    <div className={styles.indicator_add_and_edit_line_break} />
                    <div className={styles.indicator_add_and_edit_close_icon_container}>
                        <CloseIcon
                            onClick={() => {
                                props.setNumberOfDataSourceSections(1);

                                props.setUpsertMarketIndicatorFactorRequest({
                                    ...props.upsertMarketIndicatorFactorRequest,
                                    dataCombinationType: undefined,
                                    secondaryMarket: undefined,
                                    secondaryRegion: undefined,
                                    secondarySubRegion: undefined,
                                    secondaryCommodity: undefined,
                                    secondaryLeadingIndicatorType: undefined,
                                    secondaryFundamentalCategory: undefined,
                                    secondaryDataFrequency: undefined,
                                    secondaryExtraParameters: undefined,
                                });
                            }}
                        />
                    </div>
                    <MarketFactorMathOperationDropdown
                        upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                        setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                    />
                </>
            )}
        </>
    );
};

export default MarketIndicatorFactorDataSourceArea;
