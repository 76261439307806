import React, { useEffect, useRef } from 'react';
import useSearchParameters from '../Hooks/useSearchParametersHook';
import styles from './Tabs.module.scss';

interface ITabOptions {
    title: string;
    key: string;
    component?: JSX.Element;
    showTab?: boolean;
}

interface ITabsProps {
    tabOptions: ITabOptions[];
    rightSideComponent?: JSX.Element;
}

const Tabs: React.FC<ITabsProps> = (props) => {
    const [searchParamters, setSearchParameters] = useSearchParameters();
    const currentTabReference = useRef<string | undefined>(searchParamters.tab);

    useEffect(() => {
        currentTabReference.current = searchParamters.tab;

        if (searchParamters.tab) {
            return () => {};
        }

        // If there's no initial tab, set the initial tab to the first tab in the list.
        // This timeout is used to ensure mono doesn't put us in a tab setting loop.
        const timeout = setTimeout(() => {
            if (!currentTabReference.current) {
                setSearchParameters({ tab: props.tabOptions.find((tab) => tab.showTab !== false)?.key ?? '' });
            }
        });

        return () => clearTimeout(timeout);
    }, [searchParamters]);

    return (
        <>
            <div className={styles.tab_main_container}>
                {props.tabOptions.length > 0 &&
                    props.tabOptions.map(
                        (tab: ITabOptions) =>
                            tab.showTab !== false && (
                                <button
                                    type="button"
                                    className={tab.key === searchParamters.tab ? styles.tab_button_selected : styles.tab_button}
                                    key={`tab_${tab.key}`}
                                    onClick={() => {
                                        setSearchParameters({ tab: tab.key });
                                    }}
                                >
                                    {tab.title}
                                </button>
                            ),
                    )}
                {props.rightSideComponent}
                <div className={styles.tab_empty_underline_space} />
            </div>
            <div>{props.tabOptions.find((tab) => tab.showTab !== false && tab.key === searchParamters.tab)?.component}</div>
        </>
    );
};

export default Tabs;
