import { Link } from 'react-router-dom';
import scssVariables from '../../../../Config.module.scss';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { DemeterCompanyModel } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';

// Renderer functions.
export type RendererParameters = {
    data: DemeterCompanyModel;
};

const fullNameCellRenderer = (parameters: RendererParameters) => {
    const { name, demeterCompanyGuid } = parameters.data;

    return (
        <Link style={{ color: scssVariables.agGridLinkLolor }} to={`${NavigationRoutes.Companies}/${demeterCompanyGuid}/edit`}>
            {name}
        </Link>
    );
};

// Column definitions.
export const companyColumnDefinitions = [
    {
        field: 'companyName',
        headerValueGetter: () => languageService.translate(translationKeys.companies.fields.company),
        minWidth: 100,
        cellRenderer: fullNameCellRenderer,
    },
    {
        field: 'email',
        headerValueGetter: () => languageService.translate(translationKeys.companies.fields.companyAdministrator),
        minWidth: 100,
        cellRenderer: (parameters: RendererParameters) => parameters.data.companyAdministrators[0]?.email! ?? applicationConstants.TablePlaceholderZeroOrEmpty,
    },
    {
        field: 'numberOfUsers',
        headerValueGetter: () => languageService.translate(translationKeys.companies.fields.numberOfUsers),
        minWidth: 100,
        cellRenderer: (parameters: RendererParameters) => parameters.data.numberOfUsers ?? applicationConstants.TablePlaceholderZeroOrEmpty,
    },
    {
        field: 'numberOfLicenses',
        headerValueGetter: () => languageService.translate(translationKeys.companies.fields.numberOfLicenses),
        minWidth: 50,
        cellRenderer: (parameters: RendererParameters) => parameters.data.numberOfLicenses ?? applicationConstants.TablePlaceholderZeroOrEmpty,
    },
    {
        field: 'actions',
        headerValueGetter: () => languageService.translate(translationKeys.words.actions),
        minWidth: 100,
        pinned: 'right',
        lockPinned: true,
        cellRenderer: undefined,
    },
];

export const companiesColumnOptions = {
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    flex: 1,
    rowDragManaged: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: true,
    headerClass: 'ag-header-cell-flex-start',
    sortable: false,
};
