import React, { Suspense, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardPage from '../Containers/Dashboard/DashboardPage';
import { DemeterFeatureType, DemeterPermissionType, DemeterUserType } from '../Generated/Raven-Demeter';
import { useApplicationSelector } from '../Redux/ReduxStore';
import { selectUserType } from '../Redux/Slices/UserSlice';
import PageLoadingSpinner from '../Refactor/Components/LoadingSpinner/PageLoadingSpinner';
import OktaLoginCallback from '../Refactor/Core/Okta/OktaLoginCallback';
import AdministrationPage from '../Refactor/Pages/Administration/AdministrationPage';
import CompaniesPage from '../Refactor/Pages/Administration/Companies/CompaniesPage';
import CompanyPage from '../Refactor/Pages/Administration/Companies/CompanyPage';
import MarketIndicatorFactorPage from '../Refactor/Pages/Administration/MarketIndicatorsManagement/MarketIndicatorFactor/MarketIndicatorFactorPage';
import MarketIndicatorsMangementPage from '../Refactor/Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementPage';
import UserPage from '../Refactor/Pages/Administration/Users/UserPage';
import UsersImportPage from '../Refactor/Pages/Administration/Users/UsersImportPage';
import UsersPage from '../Refactor/Pages/Administration/Users/UsersPage';
import BalanceTablePage from '../Refactor/Pages/BalanceTable/BalanceTablePage';
import CalculatorsPage from '../Refactor/Pages/Calculators/CalculatorsPage';
import CancelTrialPage from '../Refactor/Pages/CancelTrial/CancelTrialPage';
import DisclaimerPage from '../Refactor/Pages/Disclaimer/DisclaimerPage';
import DomesticsPage from '../Refactor/Pages/Domestics/DomesticsPage';
import ExportsPage from '../Refactor/Pages/Exports/ExportsPage';
import FeatureFlagsPage from '../Refactor/Pages/FeatureFlags/FeatureFlagsPage';
import FrequentlyAskedQuestionsPage from '../Refactor/Pages/FrequentlyAskedQuestions/FrequentlyAskedQuestionsPage';
import FuturesRefactorPage from '../Refactor/Pages/Futures/FuturesPage';
import HedgeMonitorProPage from '../Refactor/Pages/HedgeMonitorPro/HedgeMonitorProPage';
import ImportsPage from '../Refactor/Pages/Imports/ImportsPage';
import MarketIndicatorsPage from '../Refactor/Pages/MarketIndicators/MarketIndicatorsPage';
import OutlookAndReportsPage from '../Refactor/Pages/OutlookAndReports/OutlookAndReportsPage';
import PhysicalPricesRefactorPage from '../Refactor/Pages/PhysicalPrices/PhysicalPricesPage';
import PrivacyPolicyPage from '../Refactor/Pages/PrivacyPolicy/PrivacyPolicyPage';
import ProductionPage from '../Refactor/Pages/Productions/ProductionPage';
import RegistrationActivatePage from '../Refactor/Pages/Registration/RegistrationActivatePage';
import RegistrationSelfPage from '../Refactor/Pages/Registration/RegistrationSelfPage';
import RegistrationSelfSuccessPage from '../Refactor/Pages/Registration/RegistrationSelfSuccessPage';
import RiskPage from '../Refactor/Pages/Risk/RiskPage';
import SessionExpirationPage from '../Refactor/Pages/Session/SessionExpirationPage';
import StocksPage from '../Refactor/Pages/Stocks/StocksPage';
import UserDataPage from '../Refactor/Pages/UserData/UserDataPage';
import useFeatureFlag from '../Refactor/Services/FeatureFlags/useFeatureFlagHook';
import permissionsService from '../Refactor/Services/Permissions/PermissionsService';
import MonoHomePage from './Mono/MonoHomePage';
import NavigationRoutes, { NavigationRoutesType, PublicRoutes } from './NavigationRoutes';
import StandaloneHomePage from './Standalone/StandaloneHomePage';

interface RouterRoutesProps {
    secureRouteContainer: React.ReactNode;
    isMono: boolean;
}

const MonoRouteSuffix = '/*';
const OriginalNavigationRoutes = { ...NavigationRoutes };

interface IProtectedElementProps {
    children: JSX.Element;
    featureType: DemeterFeatureType;
    permissionTypes?: DemeterPermissionType | DemeterPermissionType[];
}

const ProtectedElement: React.FC<IProtectedElementProps> = (props: IProtectedElementProps) => {
    let canAccessPage = useFeatureFlag(props.featureType);

    if (props.permissionTypes) {
        if (Array.isArray(props.permissionTypes)) {
            canAccessPage = canAccessPage && props.permissionTypes.some((x) => permissionsService.getPermission(x));
        } else {
            canAccessPage = canAccessPage && permissionsService.getPermission(props.permissionTypes);
        }
    }

    return canAccessPage ? props.children : <Navigate to={NavigationRoutes.Dashboard as string} />;
};

interface IAdministratorElementProps {
    children: JSX.Element;
}

const AdminstratorProtectedElement: React.FC<IAdministratorElementProps> = (props: IAdministratorElementProps) => {
    const currentUserType = useApplicationSelector(selectUserType);
    return currentUserType === DemeterUserType.Administrator ? props.children : <Navigate to={NavigationRoutes.Dashboard as string} />;
};

const BusinessOwnerProtectedElement: React.FC<IAdministratorElementProps> = (props: IAdministratorElementProps) => {
    const currentUserType = useApplicationSelector(selectUserType);
    return currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.BusinessOwner ? (
        props.children
    ) : (
        <Navigate to={NavigationRoutes.Dashboard as string} />
    );
};

const RouterRoutes: React.FC<RouterRoutesProps> = (props: RouterRoutesProps) => {
    // Translate the route to a regular React route or a Mono route.
    const [routes] = useState(() => {
        let translatedRoutes: NavigationRoutesType = {};

        if (props.isMono) {
            const applicationContainerRoute = OriginalNavigationRoutes.ApplicationContainer;

            Object.entries(OriginalNavigationRoutes).forEach(([key, value]) => {
                translatedRoutes[key] = `${value}${MonoRouteSuffix}`;
                (NavigationRoutes as NavigationRoutesType)[key] = PublicRoutes[key] ? `/${value}` : `/${applicationContainerRoute}/${value}`;
            });

            translatedRoutes.ApplicationContainer = `/${OriginalNavigationRoutes.ApplicationContainer}`;
        } else {
            translatedRoutes = { ...OriginalNavigationRoutes, ...{ Home: '/' } };

            const applicationContainerRoute = OriginalNavigationRoutes.ApplicationContainer;
            Object.entries(OriginalNavigationRoutes).forEach(([key, value]) => {
                (NavigationRoutes as NavigationRoutesType)[key] = PublicRoutes[key] ? `/${value}` : `/${applicationContainerRoute}/${value}`;
            });
        }
        return translatedRoutes;
    });

    return (
        <Suspense fallback={<PageLoadingSpinner />}>
            <Routes>
                {!props.isMono && (
                    <>
                        <Route path={routes.Home} element={<StandaloneHomePage />} />
                        <Route path={routes.Logout} element={<StandaloneHomePage />} />
                        <Route path={routes.LoginCallback} element={<OktaLoginCallback />} />
                        <Route path={routes.RegistrationActivate} element={<RegistrationActivatePage />} />
                        <Route path={routes.RegistrationSelf} element={<RegistrationSelfPage />} />
                        <Route path={routes.RegistrationSelfSuccess} element={<RegistrationSelfSuccessPage />} />
                        <Route path={routes.SessionExpiration} element={<SessionExpirationPage />} />
                    </>
                )}
                {props.isMono && (
                    <>
                        <Route path={routes.Home} element={<MonoHomePage />} />
                        <Route path={routes.SessionExpiration} element={<SessionExpirationPage />} />
                    </>
                )}
                <Route path={routes.ApplicationContainer} element={props.secureRouteContainer}>
                    <Route path={routes.Dashboard} element={<DashboardPage />} />
                    <Route path={routes.Futures} element={<FuturesRefactorPage />} />
                    <Route path={routes.HedgeMonitorPro} element={<HedgeMonitorProPage />} />
                    <Route path={routes.PhysicalPrices} element={<PhysicalPricesRefactorPage />} />
                    <Route path={routes.Production} element={<ProductionPage />} />
                    <Route path={routes.Imports} element={<ImportsPage />} />
                    <Route path={routes.Exports} element={<ExportsPage />} />
                    <Route path={routes.Stocks} element={<StocksPage />} />
                    <Route path={routes.Domestics} element={<DomesticsPage />} />
                    <Route path={routes.BalanceTable} element={<BalanceTablePage />} />
                    <Route
                        path={routes.MarketIndicators}
                        element={
                            <ProtectedElement
                                featureType={DemeterFeatureType.MarketIndicators}
                                permissionTypes={[
                                    DemeterPermissionType.MarketIndicators,
                                    DemeterPermissionType.MarketIndicatorAdministrator,
                                    DemeterPermissionType.MarketIndicatorDeveloper,
                                    DemeterPermissionType.MarketIndicatorSubjectMatterExpert,
                                    DemeterPermissionType.MarketIndicatorCompliance,
                                ]}
                            >
                                <MarketIndicatorsPage />
                            </ProtectedElement>
                        }
                    />
                    <Route path={routes.Calculators} element={<CalculatorsPage />} />
                    <Route
                        path={routes.Risk}
                        element={
                            <ProtectedElement
                                featureType={DemeterFeatureType.PositionsCalculator}
                                permissionTypes={[DemeterPermissionType.PositionsCalculator, DemeterPermissionType.BudgetCalculator]}
                            >
                                <RiskPage />
                            </ProtectedElement>
                        }
                    />
                    <Route path={routes.UserData} element={<UserDataPage />} />

                    <Route
                        path={routes.Administration}
                        element={
                            <ProtectedElement featureType={DemeterFeatureType.Administration}>
                                <AdministrationPage />
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path={routes.Companies}
                        element={
                            <BusinessOwnerProtectedElement>
                                <ProtectedElement featureType={DemeterFeatureType.Companies}>
                                    <CompaniesPage />
                                </ProtectedElement>
                            </BusinessOwnerProtectedElement>
                        }
                    />
                    <Route
                        path={routes.CompaniesAdd}
                        element={
                            <BusinessOwnerProtectedElement>
                                <ProtectedElement featureType={DemeterFeatureType.Companies}>
                                    <CompanyPage />
                                </ProtectedElement>
                            </BusinessOwnerProtectedElement>
                        }
                    />
                    <Route
                        path={routes.CompaniesEdit}
                        element={
                            <BusinessOwnerProtectedElement>
                                <ProtectedElement featureType={DemeterFeatureType.Companies}>
                                    <CompanyPage />
                                </ProtectedElement>
                            </BusinessOwnerProtectedElement>
                        }
                    />
                    <Route
                        path={routes.CompaniesUsers}
                        element={
                            <ProtectedElement featureType={DemeterFeatureType.Users}>
                                <UsersPage />
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path={routes.Users}
                        element={
                            <ProtectedElement featureType={DemeterFeatureType.Users}>
                                <UsersPage />
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path={routes.UsersAdd}
                        element={
                            <ProtectedElement featureType={DemeterFeatureType.Users}>
                                <UserPage />
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path={routes.UsersEdit}
                        element={
                            <ProtectedElement featureType={DemeterFeatureType.Users}>
                                <UserPage />
                            </ProtectedElement>
                        }
                    />
                    <Route
                        path={routes.UsersImport}
                        element={
                            <BusinessOwnerProtectedElement>
                                <ProtectedElement featureType={DemeterFeatureType.Users}>
                                    <UsersImportPage />
                                </ProtectedElement>
                            </BusinessOwnerProtectedElement>
                        }
                    />
                    <Route
                        path={routes.AdministrationMarketIndicators}
                        element={
                            <BusinessOwnerProtectedElement>
                                <ProtectedElement featureType={DemeterFeatureType.MarketIndicators}>
                                    <MarketIndicatorsMangementPage />
                                </ProtectedElement>
                            </BusinessOwnerProtectedElement>
                        }
                    />
                    <Route
                        path={routes.AdministrationMarketIndicatorEdit}
                        element={
                            <BusinessOwnerProtectedElement>
                                <ProtectedElement featureType={DemeterFeatureType.MarketIndicators}>
                                    <MarketIndicatorsPage isInAdministrationMode />
                                </ProtectedElement>
                            </BusinessOwnerProtectedElement>
                        }
                    />
                    <Route
                        path={routes.AdministrationMarketFactorAdd}
                        element={
                            <BusinessOwnerProtectedElement>
                                <ProtectedElement featureType={DemeterFeatureType.MarketIndicators}>
                                    <MarketIndicatorFactorPage />
                                </ProtectedElement>
                            </BusinessOwnerProtectedElement>
                        }
                    />
                    <Route
                        path={routes.AdministrationMarketFactorEdit}
                        element={
                            <BusinessOwnerProtectedElement>
                                <ProtectedElement featureType={DemeterFeatureType.MarketIndicators}>
                                    <MarketIndicatorFactorPage />
                                </ProtectedElement>
                            </BusinessOwnerProtectedElement>
                        }
                    />
                    <Route
                        path={routes.FeatureFlags}
                        element={
                            <AdminstratorProtectedElement>
                                <FeatureFlagsPage />
                            </AdminstratorProtectedElement>
                        }
                    />
                    <Route path={routes.Faqs} element={<FrequentlyAskedQuestionsPage />} />
                    <Route path={routes.Disclaimer} element={<DisclaimerPage />} />
                    <Route path={routes.PrivacyPolicy} element={<PrivacyPolicyPage />} />
                    <Route path={routes.OutlookAndReports} element={<OutlookAndReportsPage />} />
                    <Route path={routes.CancelTrial} element={<CancelTrialPage />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default RouterRoutes;
